.modal-video {
  background: rgba(var(--color-foreground), 0.2);
  box-sizing: border-box;
  height: 100%;
  left: 0;
  margin: 0 auto;
  opacity: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: -1;
}

.modal-video[open] {
  opacity: 1;
  visibility: visible;
  z-index: 101;
}

.modal-video__content {
  background-color: rgb(var(--color-background));
  height: 100%;
  margin: 0;
  overflow: auto;
  padding: 0;
  position: absolute;
  width: 100%;
}

.modal-video__toggle {
  align-items: center;
  background-color: rgb(var(--color-background));
  border-radius: 50%;
  border: 0.1rem solid rgba(var(--color-foreground), 0.1);
  color: rgba(var(--color-foreground), 0.55);
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 0 0 0 auto;
  padding: 1.2rem;
  position: fixed;
  right: 0.5rem;
  top: 2rem;
  width: 4rem;
  z-index: 2;
}

@media screen and (min-width: 750px) {
  .modal-video__toggle {
    right: 4.8rem;
    top: 3.5rem;
  }
}

@media screen and (min-width: 990px) {
  .modal-video__toggle {
    right: 4.3rem;
    top: 3rem;
  }
}

.modal-video__toggle .icon {
  height: auto;
  margin: 0;
  width: 2.2rem;
}

.modal-video__content-info {
  height: calc(100% - 6rem);
  margin: 0 auto;
  padding-top: 8rem;
  width: calc(100% - 1rem);
}

@media screen and (min-width: 750px) {
  .modal-video__content-info {
    height: calc(100% - 7.5rem);
    padding-top: 9.5rem;
    width: calc(100% - 9.6rem);
  }
}

@media screen and (min-width: 990px) {
  .modal-video__content-info {
    height: calc(100% - 7rem);
    padding-top: 9rem;
    width: calc(100% - 8.6rem);
  }
}

.modal-video__video,
.modal-video__video iframe {
  height: 100%;
  width: 100%;
}

.modal-video__video iframe {
  position: static;
  border: 0;
}
